import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  Linkable
} from "./chunk.VPBTB6HE.js";
import {
  ButtonElement
} from "./chunk.K64X5MIA.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-button-secondary/jb-button-secondary.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-button-secondary/jb-button-secondary.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_button_secondary_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
a,
button {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 900;
}

a,
button {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 16px;
  text-decoration: none;
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host,
::before,
::after {
  display: inline-block;
  inline-size: var(--button-width, initial);
  --_button-border-color: 20 30 85 / 1;
  --_button-transition-duration: 2s;
  --_button-transition-easing: cubic-bezier(0.16, 1, 0.3, 1);
  --_button-bottom-border-width: 1px;
  --_button-bottom-border-spacing: 1px;
  --_button-text-color: 20, 30, 85;
  --_icon-color: var(--button-text-color);
}

::slotted(*) {
  margin-inline-end: 0.5rem;
}

a,
button {
  align-items: center;
  background-color: transparent;
  border: var(--_button-bottom-border-width) solid rgb(var(--button-border-color, var(--_button-border-color)));
  color: rgba(var(--_button-text-color), 1);
  cursor: pointer;
  display: flex;
  inline-size: 100%;
  justify-content: center;
  padding: var(--_button-padding, 11px 32px);
  position: relative;
  text-align: center;
  user-select: none;
}
a::before,
button::before {
  background-color: rgb(var(--button-border-color, var(--_button-border-color)));
  block-size: var(--_button-bottom-border-width);
  content: "";
  display: block;
  inline-size: calc(100% + 2px);
  inset-block-end: var(--_button-bottom-border-spacing);
  inset-inline-start: -1px;
  position: absolute;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform var(--_button-transition-duration) var(--_button-transition-easing);
}
a.hover::before, a:hover::before,
button.hover::before,
button:hover::before {
  transform: scaleX(1);
}
a:active,
button:active {
  transform: scale(0.99);
}

.inverted,
.inverted::before {
  --_button-border-color: 255 255 255 / 1;
  --_button-text-color: 255, 255, 255;
}

.inverted.inactive,
.inverted.inactive::before {
  --_button-border-color: 255 255 255 / 0.4;
  color: rgba(var(--_button-text-color), 0.7);
  pointer-events: none;
}

:not(.inverted).inactive {
  opacity: 0.4;
  pointer-events: none;
}`;

// src/components/jb-button-secondary/jb-button-secondary.ts
var JbButtonSecondary = class extends Initiable(ButtonElement(Linkable(LitElement))) {
  render() {
    const classes = { inactive: !!this.inactive, hover: !!this.forceHover, inverted: !!this.inverted };
    return this.link?.href ? html`<a
          data-linkable
          class=${classMap(classes)}
        >
          <slot></slot>
        </a>` : html`<button
          ?disabled=${this.inactive}
          class=${classMap(classes)}
        >
          <slot></slot>
        </button>`;
  }
};
JbButtonSecondary.styles = unsafeCSS(jb_button_secondary_default);
__decorateClass([
  property({ type: Boolean })
], JbButtonSecondary.prototype, "forceHover", 2);
__decorateClass([
  property({ type: Boolean })
], JbButtonSecondary.prototype, "inactive", 2);
__decorateClass([
  property({ type: Boolean })
], JbButtonSecondary.prototype, "inverted", 2);
__decorateClass([
  property({ type: Object })
], JbButtonSecondary.prototype, "link", 2);
__decorateClass([
  property()
], JbButtonSecondary.prototype, "name", 2);
JbButtonSecondary = __decorateClass([
  customElement("jb-button-secondary")
], JbButtonSecondary);

export {
  JbButtonSecondary
};
