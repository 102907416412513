import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/mixins/FormElement.ts
import { property, state } from "lit/decorators.js";
function FormElement(baseClass) {
  class FormElementMixin extends baseClass {
    constructor() {
      super(...arguments);
      this.disabled = false;
      this.errorText = "";
      this.frontendRenderingIdentifier = "";
      this.label = "";
      this.requiredText = "";
      this.showError = false;
      this.value = "";
    }
    connectedCallback() {
      super.connectedCallback();
      this.populateValue();
    }
    updated(changedProperties) {
      this.dispatchEvent(
        new CustomEvent("field-update", {
          bubbles: true,
          detail: { field: this, changedProperties }
        })
      );
    }
    appendFormData(formDataEvent) {
      formDataEvent.formData.append(this.name, this.value || "");
    }
    populateValue() {
      if (this.frontendRenderingIdentifier) {
        let storedData = localStorage.getItem("savedFormData");
        if (storedData === null) {
          return;
        }
        storedData = JSON.parse(storedData);
        if (this.frontendRenderingIdentifier && this.frontendRenderingIdentifier in storedData) {
          this.value = storedData[this.frontendRenderingIdentifier];
        }
      }
    }
    validate() {
      const valid = this.disabled || (this.requiredText ? !!this.value : true);
      this.showError = !valid;
      this.errorText = "";
      return valid;
    }
  }
  __decorateClass([
    property({ type: Boolean })
  ], FormElementMixin.prototype, "disabled", 2);
  __decorateClass([
    property()
  ], FormElementMixin.prototype, "errorText", 2);
  __decorateClass([
    property()
  ], FormElementMixin.prototype, "frontendRenderingIdentifier", 2);
  __decorateClass([
    property()
  ], FormElementMixin.prototype, "label", 2);
  __decorateClass([
    property()
  ], FormElementMixin.prototype, "name", 2);
  __decorateClass([
    property()
  ], FormElementMixin.prototype, "requiredText", 2);
  __decorateClass([
    state()
  ], FormElementMixin.prototype, "showError", 2);
  __decorateClass([
    property()
  ], FormElementMixin.prototype, "value", 2);
  return FormElementMixin;
}

export {
  FormElement
};
