import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-highlight-text-item/jb-highlight-text-item.ts
import { html, LitElement, nothing, svg, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { unsafeSVG } from "lit/directives/unsafe-svg.js";

// src/components/jb-highlight-text-item/jb-highlight-text-item.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_highlight_text_item_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
p, h3 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

h3 {
  font-size: 22px;
  line-height: 28px;
}
@media (min-width: 1281px) {
  h3 {
    font-size: 26px;
    line-height: 36px;
  }
}

p {
  font-size: 16px;
  line-height: 26px;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  text-align: center;
}

svg {
  margin-bottom: 16px;
  max-width: 80px;
}

h3 {
  margin-bottom: 12px;
}`;

// src/components/jb-highlight-text-item/jb-highlight-text-item.ts
var JbHighlightTextItem = class extends LitElement {
  render() {
    return html`
      ${this.svgIcon ? svg`${unsafeSVG(this.svgIcon)}` : nothing}
      ${this.headline ? html`<h3>${this.headline}</h3>` : nothing}
      ${this.subline ? html`<p>${this.subline}</p>` : nothing}
    `;
  }
};
JbHighlightTextItem.styles = unsafeCSS(jb_highlight_text_item_default);
__decorateClass([
  property()
], JbHighlightTextItem.prototype, "headline", 2);
__decorateClass([
  property()
], JbHighlightTextItem.prototype, "subline", 2);
__decorateClass([
  property()
], JbHighlightTextItem.prototype, "svgIcon", 2);
JbHighlightTextItem = __decorateClass([
  customElement("jb-highlight-text-item")
], JbHighlightTextItem);

export {
  JbHighlightTextItem
};
