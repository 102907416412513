import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-listing-filter/jb-listing-filter.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-listing-filter/jb-listing-filter.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_listing_filter_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
li {
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
}
li::before {
  animation-direction: reverse;
  background-color: rgba(241, 242, 242, 0.3);
  bottom: 0;
  content: "";
  height: 66%;
  left: 0;
  position: absolute;
  transition: height 0.9s ease-out 0.2s;
  width: 1px;
}
li::after {
  background: #fff;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 50%;
  transition: right 0.9s ease-out 0.2s;
}

/* stylelint-disable */
/* stylelint-enable */
a {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 700;
}

a {
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: flex;
  justify-content: center;
}

ul {
  display: flex;
  gap: var(--spacing-xs);
  list-style-type: none;
  overflow-x: auto;
  white-space: nowrap;
}

li {
  display: inline-flex;
  min-height: 0;
  opacity: 0.4;
  overflow: visible;
  padding-block: var(--spacing-base);
  transition: opacity 0.4s ease-in-out 0s;
  white-space: nowrap;
}
li::before {
  background-color: transparent;
  border-bottom: 1px solid rgba(20, 30, 85, 0.2);
  height: 1px;
  width: 100%;
}
li::after {
  background: #001489;
  right: 100%;
}
li:hover, li.active {
  opacity: 1;
}
li:hover::after, li.active::after {
  right: 0 !important;
  transition-duration: 0.5s !important;
}

a {
  color: #141e55;
  text-decoration: none;
}`;

// src/components/jb-listing-filter/jb-listing-filter.ts
var JbListingFilter = class extends LitElement {
  _handleFilterClick(event) {
    event.preventDefault();
    const newValue = event.target.dataset.filterValue;
    if (this.activeValue === newValue) {
      return;
    }
    this.activeValue = newValue;
    this.dispatchEvent(
      new CustomEvent("jb-listing-filter:change", {
        detail: {
          filterAspect: this.filterAspect,
          newValue
        },
        bubbles: true,
        composed: true
      })
    );
    this.requestUpdate();
  }
  render() {
    return html`
      <ul>
        ${this.options.map(
      (option) => html`<li class="${classMap({ active: option.value === this.activeValue })}">
              <a
                @click=${this._handleFilterClick}
                data-filter-value=${option.value}
                href="#"
                >${option.label}</a
              >
            </li>`
    )}
      </ul>
    `;
  }
};
JbListingFilter.styles = unsafeCSS(jb_listing_filter_default);
__decorateClass([
  property()
], JbListingFilter.prototype, "activeValue", 2);
__decorateClass([
  property()
], JbListingFilter.prototype, "filterAspect", 2);
__decorateClass([
  property({ type: Array })
], JbListingFilter.prototype, "options", 2);
JbListingFilter = __decorateClass([
  customElement("jb-listing-filter")
], JbListingFilter);

export {
  JbListingFilter
};
