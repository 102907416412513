import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-tag/jb-tag.ts
import { html, LitElement, svg, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-tag/jb-tag.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_tag_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  cursor: pointer;
  display: block;
}

div {
  align-items: center;
  border: solid 2px #fff;
  display: flex;
  height: 50px;
  justify-content: center;
  outline: none;
  padding: 14px;
  transition: margin 0.7s, width 0.7s, height 0.7s;
  width: 50px;
  z-index: 2;
}

svg {
  height: 18px;
  outline: none;
  transition: transform 0.7s;
  width: 18px;
}

div.large {
  height: 70px;
  margin: 30px;
  width: 70px;
}
div.large svg {
  transform: scale(1.3333);
}`;

// src/components/jb-tag/jb-tag.ts
var JbTag = class extends LitElement {
  render() {
    return html`
      <div class="${classMap({ large: this.isLarge || false })}">
        ${this.type === "play" ? svg`<svg viewbox="0 0 18 18" focusable="false" tabindex="0" xmlns="http://www.w3.org/2000/svg" > <g fill="#FFF" fill-rule="evenodd" > <path fill="none" d="M0 0h18v18H0z" ></path> <path d="M13.308 9.192l-6.345 6.345a.271.271 0 01-.463-.192V2.655a.271.271 0 01.463-.192l6.345 6.345a.271.271 0 010 .384z" ></path> </g> </svg>` : svg`<svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 13 13" > <path fill="#fff" d="M12.858001708984375,11.161001205444336 c0,0.7889999747276306 -0.6399999856948853,1.4290000200271606 -1.4290000200271606,1.4290000200271606 h-0.7139999866485596 a0.7139999866485596,0.7139999866485596 0 0 1 -0.7149999737739563,-0.7149999737739563 v-3.571000099182129 c0,-0.39500001072883606 0.3199999928474426,-0.7139999866485596 0.7149999737739563,-0.7139999866485596 h1.1430000066757202 v-1.0080000162124634 c0,-3.0850000381469727 -2.434000015258789,-5.581999778747559 -5.428999900817871,-5.581999778747559 c-2.937999963760376,0 -5.335000038146973,2.4019999504089355 -5.426000118255615,5.40500020980835 l-0.003000000026077032,0.1770000010728836 v1.0080000162124634 h1.1430000066757202 c0.39500001072883606,0 0.7149999737739563,0.3199999928474426 0.7149999737739563,0.7139999866485596 v3.571000099182129 c0,0.39500001072883606 -0.3199999928474426,0.7149999737739563 -0.7149999737739563,0.7149999737739563 H1.4290008544921875 c-0.7889999747276306,0 -1.4290000200271606,-0.6399999856948853 -1.4290000200271606,-1.4290000200271606 v-4.578999996185303 c0,-3.631999969482422 2.875999927520752,-6.581999778747559 6.428999900817871,-6.581999778747559 c3.490999937057495,0 6.327000141143799,2.8469998836517334 6.426000118255615,6.392000198364258 l0.003000000026077032,0.1899999976158142 v4.578999996185303 z" /> </svg>`}
      </div>
    `;
  }
};
JbTag.styles = unsafeCSS(jb_tag_default);
__decorateClass([
  property({ type: Boolean })
], JbTag.prototype, "isLarge", 2);
__decorateClass([
  property()
], JbTag.prototype, "type", 2);
JbTag = __decorateClass([
  customElement("jb-tag")
], JbTag);

export {
  JbTag
};
