import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/mixins/Filterable.ts
import { property } from "lit/decorators.js";
function Filterable(baseClass) {
  class FilterableMixin extends baseClass {
    markAsDisabled() {
      this.setAttribute("data-disabled-by-filter", "");
    }
    markAsEnabled() {
      this.removeAttribute("data-disabled-by-filter");
    }
    matchesFilterValue(filterValueToCompareTo) {
      return !!this.filterValues?.find(
        (filterValue) => filterValue.filterAspect === filterValueToCompareTo.filterAspect && filterValue.value === filterValueToCompareTo.value
      );
    }
  }
  __decorateClass([
    property({ type: Array })
  ], FilterableMixin.prototype, "filterValues", 2);
  return FilterableMixin;
}

export {
  Filterable
};
