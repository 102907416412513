import {
  debounce
} from "./chunk.SYAPDNOX.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-thumbnail/jb-thumbnail.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

// src/components/jb-thumbnail/jb-thumbnail.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_thumbnail_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

div {
  overflow: hidden;
  position: relative;
}
div video,
div jb-picture {
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s;
  width: 100%;
}
div::before {
  background: var(--gradient-thumbnail, linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)));
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}
div.aspect_3by2 {
  aspect-ratio: 3/2;
}
div.aspect_16by9 {
  aspect-ratio: 16/9;
}
div.aspect_21by9 {
  aspect-ratio: 21/9;
}

jb-picture,
video,
jb-tag {
  transform: var(--transform-thumbnail, none);
}

jb-tag {
  bottom: 30px;
  left: 30px;
  position: absolute;
  top: auto;
  transform: var(--transform-tag, var(--transform-thumbnail, scale(1)));
  transition: transform 0.7s, width 0.7s, height 0.7s;
  z-index: 1;
}`;

// src/components/jb-thumbnail/jb-thumbnail.ts
var JbThumbnail = class extends LitElement {
  constructor() {
    super(...arguments);
    this._BREAKPOINT = 520;
    this._observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.play();
        } else {
          entry.target.pause();
        }
      });
    });
  }
  connectedCallback() {
    super.connectedCallback();
    this._largeTag = this.offsetWidth > this._BREAKPOINT;
    window.addEventListener("resize", debounce(this._windowResized.bind(this), 500));
  }
  firstUpdated() {
    if (!this._video)
      return;
    this._observer.observe(this._video);
    this._video.muted = true;
  }
  _windowResized() {
    this._largeTag = this.offsetWidth > this._BREAKPOINT;
  }
  render() {
    return html`
      <div class="${this.aspect}">
        ${this.videoSrc ? html`<video
              src="${this.videoSrc}"
              loop
              muted
            ></video>` : html`<jb-picture
              .fit=${"cover"}
              .imgData=${this.backgroundPicture.imgData}
              .lazy=${this.backgroundPicture.lazy}
            ></jb-picture>`}
        ${this.type === "audio" ? html`<jb-tag
              .type=${"audio"}
              .isLarge=${this._largeTag}
            ></jb-tag>` : nothing}
        ${this.type === "video" ? html`<jb-tag
              .type=${"play"}
              .isLarge=${this._largeTag}
            ></jb-tag>` : nothing}
      </div>
    `;
  }
};
JbThumbnail.styles = unsafeCSS(jb_thumbnail_default);
__decorateClass([
  property()
], JbThumbnail.prototype, "aspect", 2);
__decorateClass([
  property({ type: Object })
], JbThumbnail.prototype, "backgroundPicture", 2);
__decorateClass([
  property()
], JbThumbnail.prototype, "type", 2);
__decorateClass([
  property()
], JbThumbnail.prototype, "videoSrc", 2);
__decorateClass([
  state()
], JbThumbnail.prototype, "_largeTag", 2);
__decorateClass([
  query("video")
], JbThumbnail.prototype, "_video", 2);
JbThumbnail = __decorateClass([
  customElement("jb-thumbnail")
], JbThumbnail);

export {
  JbThumbnail
};
