import {
  setLink
} from "./chunk.VPBTB6HE.js";
import {
  nlToBr
} from "./chunk.SYAPDNOX.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-contact/jb-contact.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

// src/components/jb-contact/jb-contact.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_contact_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
h4 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 700;
}

.link, p {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

p {
  font-size: 16px;
  line-height: 26px;
}

h4 {
  font-size: 16px;
  line-height: 26px;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

.wrapper {
  border-top-color: rgba(20, 30, 85, 0.2); /* stylelint-disable-line */
  border-top-style: solid; /* stylelint-disable-line */
  border-top-width: var(--contact-wrapper-top-border, 1px); /* stylelint-disable-line */
  padding-bottom: var(--contact-wrapper-bottom-padding, 40px);
}

article {
  padding-top: var(--contact-article-top-padding, 40px);
  position: relative;
  z-index: 10;
}

h4 {
  color: #141e55;
  margin-bottom: 16px;
}
@media (min-width: 960px) {
  h4 {
    margin-bottom: 30px;
  }
}
* + h4 {
  margin-top: 51px;
}

.textcontent {
  flex-basis: 40%;
  flex-grow: 2;
}

.link {
  color: rgba(20, 30, 85, 0.8);
}

.links {
  margin-top: 16px;
}
@media (min-width: 960px) {
  .links {
    margin-top: 30px;
  }
}

.maillink a::before {
  content: "> ";
}

jb-picture {
  height: 97px;
  margin-left: 12px;
  width: 74px;
}
@media (min-width: 960px) {
  jb-picture {
    height: 137px;
    width: 104px;
  }
}

.withimage {
  display: flex;
  justify-content: space-between;
}
.withimage article {
  flex-basis: 40%;
  flex-grow: 2;
}

p {
  color: rgba(20, 30, 85, 0.8);
  margin-bottom: 0;
}
p + p {
  margin-top: 0;
}
p span {
  display: inline-block;
  margin-right: 5px;
}
p span::after {
  content: ":";
}

a {
  color: RGB(var(--rgb-primary, 20, 30, 85));
  box-shadow: inset 0 -1px 0 0 RGBA(var(--rgb-primary, 20, 30, 85), 0.3);
  padding-bottom: 0.15em;
  text-decoration: none;
  transition: box-shadow 0.2s;
}
a:hover {
  box-shadow: inset 0 -1px 0 0 RGB(var(--rgb-primary, 20, 30, 85));
}`;

// src/components/jb-contact/jb-contact.ts
var JbContact = class extends LitElement {
  constructor() {
    super(...arguments);
    this.documents = [];
  }
  static _cleanPhoneNumber(input) {
    return input.trim().replace("(0)", "").replace(" ", "").replace(/[^\d#+*]/, "");
  }
  _renderDocuments() {
    return this.documents?.map((singleDocument) => {
      const anchor = document.createElement("a");
      if (singleDocument.documentLink.link)
        setLink(anchor, singleDocument.documentLink.link);
      anchor.innerText = (singleDocument.title ?? singleDocument.documentLink.text) + " | " + singleDocument.size;
      return html`<p>${anchor}</p>`;
    });
  }
  static _renderLink(link) {
    const anchor = document.createElement("a");
    if (link.link) {
      setLink(anchor, link.link);
    }
    anchor.innerText = link.text ?? "";
    return html`${anchor}`;
  }
  render() {
    return html`
      <div class="wrapper">
        <article class="${classMap({ withimage: !!this.image })}">
          <div class="textcontent">
            <h4>${this.heading}</h4>
            ${this.address ? html`<p>${unsafeHTML(nlToBr(this.address))}</p>` : nothing}
            ${this.area ? html`<p>${this.area}</p>` : nothing}
            ${this.position ? html`<p>${this.position}</p>` : nothing}
            ${this.phoneNumber && this.phoneNumberLabel ? html`<p>
                  <span>${this.phoneNumberLabel}</span>${JbContact._renderLink({
      text: this.phoneNumber,
      link: { href: "tel:" + JbContact._cleanPhoneNumber(this.phoneNumber) }
    })}
                </p>` : nothing}
            ${this.faxNumber && this.faxNumberLabel ? html`<p><span>${this.faxNumberLabel}</span>${this.faxNumber}</p>` : nothing}
            ${this.documents && this.documents.length > 0 ? html`${this._renderDocuments()}` : nothing}
            ${this.additionalText ? html`<p>${unsafeHTML(nlToBr(this.additionalText))}</p>` : nothing}
            ${this.link || this.contactLink ? html`<p class="links">
                  ${this.contactLink ? html`<p class="maillink">${JbContact._renderLink(this.contactLink)}</p>` : nothing}
                  ${this.link ? html`<p class="maillink">${JbContact._renderLink(this.link)}</p>` : nothing}
                </p>` : nothing}
          </div>
          ${this.image && html`<jb-picture
            .imgData=${this.image.imgData}
            ?lazy=${this.image.lazy}
          ></jb-picture>`}
        </article>
      </div>
    `;
  }
};
JbContact.styles = unsafeCSS(jb_contact_default);
__decorateClass([
  property()
], JbContact.prototype, "additionalText", 2);
__decorateClass([
  property()
], JbContact.prototype, "address", 2);
__decorateClass([
  property()
], JbContact.prototype, "area", 2);
__decorateClass([
  property({ type: Object })
], JbContact.prototype, "contactLink", 2);
__decorateClass([
  property({ type: Array })
], JbContact.prototype, "documents", 2);
__decorateClass([
  property()
], JbContact.prototype, "faxNumber", 2);
__decorateClass([
  property()
], JbContact.prototype, "faxNumberLabel", 2);
__decorateClass([
  property()
], JbContact.prototype, "heading", 2);
__decorateClass([
  property({ type: Object })
], JbContact.prototype, "image", 2);
__decorateClass([
  property({ type: Object })
], JbContact.prototype, "link", 2);
__decorateClass([
  property()
], JbContact.prototype, "phoneNumber", 2);
__decorateClass([
  property()
], JbContact.prototype, "phoneNumberLabel", 2);
__decorateClass([
  property()
], JbContact.prototype, "position", 2);
JbContact = __decorateClass([
  customElement("jb-contact")
], JbContact);

export {
  JbContact
};
