import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-section-headline/jb-section-headline.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-section-headline/jb-section-headline.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_section_headline_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
span, h2 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

h2 {
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 36px;
}
@media (min-width: 1281px) {
  h2 {
    font-size: 31px;
    letter-spacing: 4px;
    line-height: 40px;
  }
}

span {
  font-size: 13px;
  line-height: 20px;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

div {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

h2 {
  letter-spacing: var(--headline-letter-spacing, 2px);
  min-width: 100%;
  text-align: var(--headline-text-align, center);
  text-transform: var(--headline-text-transform, uppercase);
}
.left h2 {
  --headline-text-align: left;
}
@media (min-width: 1281px) {
  h2 {
    letter-spacing: var(--headline-letter-spacing, 4px);
  }
}

span {
  background-color: rgba(113, 124, 125, 0.1);
  color: #141e55;
  display: inline-block;
  margin-top: 23px;
  padding: 3px 8px;
  text-transform: uppercase;
}
@media (min-width: 600px) {
  span {
    margin-top: 3px;
    position: absolute;
    right: 0;
  }
}`;

// src/components/jb-section-headline/jb-section-headline.ts
var JbSectionHeadline = class extends LitElement {
  render() {
    return html`<div class="${classMap({ left: this.headlineAlign === "left" })}">
      <h2>${this.headline}</h2>
      ${this.languageBadge ? html`<span>${this.languageBadge}</span>` : nothing}
    </div>`;
  }
};
JbSectionHeadline.styles = unsafeCSS(jb_section_headline_default);
__decorateClass([
  property()
], JbSectionHeadline.prototype, "headline", 2);
__decorateClass([
  property()
], JbSectionHeadline.prototype, "headlineAlign", 2);
__decorateClass([
  property()
], JbSectionHeadline.prototype, "languageBadge", 2);
JbSectionHeadline = __decorateClass([
  customElement("jb-section-headline")
], JbSectionHeadline);

export {
  JbSectionHeadline
};
