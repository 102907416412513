import {
  Linkable
} from "./chunk.VPBTB6HE.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-breadcrumbs-item/jb-breadcrumbs-item.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-breadcrumbs-item/jb-breadcrumbs-item.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_breadcrumbs_item_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
span {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 700;
}

span {
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

li {
  display: inline-block;
  padding-left: var(--padding-left, 0);
  position: relative;
}
li.disabled {
  pointer-events: none;
}
li ::after {
  background-color: rgb(var(--rgb-primary, 20, 30, 85));
  border-radius: 100%;
  content: "";
  display: var(--dot-display, none);
  height: 3px;
  position: absolute;
  right: -13px;
  top: 53%;
  transform: translateY(-53%);
  width: 3px;
}

a {
  text-decoration: none;
}

span {
  transition: opacity 0.4s ease-in-out 0s;
}
li.disabled span, span:hover {
  opacity: 0.4;
}`;

// src/components/jb-breadcrumbs-item/jb-breadcrumbs-item.ts
var JbBreadcrumbsItem = class extends Linkable(LitElement) {
  render() {
    return html`
      <li
        class=${classMap({
      disabled: !this.link?.href
    })}
      >
        <a data-linkable><span>${this.text}</span></a>
      </li>
    `;
  }
};
JbBreadcrumbsItem.styles = unsafeCSS(jb_breadcrumbs_item_default);
__decorateClass([
  property()
], JbBreadcrumbsItem.prototype, "text", 2);
JbBreadcrumbsItem = __decorateClass([
  customElement("jb-breadcrumbs-item")
], JbBreadcrumbsItem);

export {
  JbBreadcrumbsItem
};
