import {
  Spaceable
} from "./chunk.SHDHDUWS.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-image/jb-image.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";

// src/components/jb-image/jb-image.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_image_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
.caption, .credit, .text {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.text {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, 0.8));
  font-size: 16px;
  line-height: 26px;
}

.credit {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, 0.7));
  font-size: 13px;
  line-height: 20px;
}

.caption {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 21px;
  line-height: 34px;
}
@media (min-width: 1281px) {
  .caption {
    font-size: 24px;
    line-height: 36px;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

@media (max-width: 599px) {
  p {
    margin-left: var(--paragraphs-margin-left, 6.68%);
    margin-right: var(--paragraphs-margin-right, 6.68%);
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  p {
    margin-left: var(--paragraphs-margin-left, calc(8.35% + 20px));
    margin-right: var(--paragraphs-margin-right, calc(8.35% + 20px));
  }
}

.caption {
  font-size: var(--image-caption-font-size, 21px);
  line-height: var(--image-caption-line-height, 34px);
  margin-top: var(--image-caption-margin-top, 16px);
}
@media (min-width: 1281px) {
  .caption {
    font-size: var(--image-caption-font-size, 24px);
    line-height: var(--image-caption-line-height, 36px);
  }
}

.text {
  margin-top: 16px;
}

.credit {
  color: rgba(20, 30, 85, 0.7);
  margin-top: var(--image-credit-margin-top, 5px);
}`;

// src/components/jb-image/jb-image.ts
var JbImage = class extends Spaceable(LitElement) {
  _getContentSpacingProperties() {
    return { type: "media" };
  }
  render() {
    return html`
      <div>
        <jb-picture
          .alt=${this.picture.alt}
          .imgData=${this.picture.imgData}
          .lazy=${this.picture.lazy}
        ></jb-picture>
        ${this.caption ? html`<p class="caption">${this.caption}</p>` : nothing}
        ${this.text ? html`<p class="text">${this.text}</p>` : nothing}
        ${this.credit ? html`<p class="credit">${this.credit}</p>` : nothing}
      </div>
    `;
  }
};
JbImage.styles = unsafeCSS(jb_image_default);
__decorateClass([
  property()
], JbImage.prototype, "caption", 2);
__decorateClass([
  property()
], JbImage.prototype, "credit", 2);
__decorateClass([
  property({ type: Object })
], JbImage.prototype, "picture", 2);
__decorateClass([
  property()
], JbImage.prototype, "text", 2);
JbImage = __decorateClass([
  customElement("jb-image")
], JbImage);

export {
  JbImage
};
