import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-region-indicator/jb-region-indicator.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, queryAssignedElements } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/jb-region-indicator/jb-region-indicator.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_region_indicator_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

ul {
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  align-items: center;
  cursor: pointer;
  display: var(--display, flex);
  font-size: 0;
  padding: 0;
  position: relative;
  z-index: 10;
}
ul::before {
  animation-direction: reverse;
  background-color: rgba(var(--rgb-primary, 255, 255, 255), 0.2);
  bottom: 0;
  content: "";
  height: 30px;
  left: 0;
  position: absolute;
  transition: height 0.3s ease 0.2s;
  width: 1px;
}
ul::after {
  background: rgba(var(--rgb-primary, 255, 255, 255), 0.2);
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  right: 100%;
  transition: right 0.3s ease-in-out 0.2s;
}
ul:hover::before {
  height: 0;
}
ul:hover::after {
  right: 10%;
}
@media (min-width: 960px) {
  ul {
    padding-right: 50px;
  }
}
@media (min-width: 960px) {
  ul.has-two-items {
    padding-right: 30px;
  }
}
ul::before {
  bottom: 0;
  content: "";
  display: none;
  height: var(--before-height, 33px);
  left: 0;
  position: absolute;
  width: 1px;
}
@media (min-width: 600px) {
  ul::before {
    display: block;
  }
}
ul::after {
  right: var(--after-right, 100%);
  top: 35px;
}
@media (max-width: 599px) {
  ul::after {
    display: none;
  }
}
ul:hover::after {
  right: 30px;
}
@supports (-webkit-appearance: none) {
  ul:hover::after {
    right: 13%;
  }
}
@media all and (-ms-high-contrast: none) {
  ul:hover::after {
    right: 13%;
  }
}

::slotted(jb-region-indicator-item:not(:last-of-type)) {
  --dot-display: block;
  --padding-right: 20px;
}`;

// src/components/jb-region-indicator/jb-region-indicator.ts
var JbRegionIndicator = class extends LitElement {
  firstUpdated() {
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent("region-indicator:clicked", { bubbles: true, composed: true }));
    });
    this.requestUpdate();
  }
  render() {
    return html`<ul class=${classMap({ "has-two-items": this._assignedElements?.length === 2 })}>
      <slot></slot>
    </ul>`;
  }
};
JbRegionIndicator.styles = unsafeCSS(jb_region_indicator_default);
__decorateClass([
  queryAssignedElements()
], JbRegionIndicator.prototype, "_assignedElements", 2);
JbRegionIndicator = __decorateClass([
  customElement("jb-region-indicator")
], JbRegionIndicator);

export {
  JbRegionIndicator
};
