import {
  Spaceable
} from "./chunk.SHDHDUWS.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.VPBTB6HE.js";
import {
  ChangesBackgroundColor
} from "./chunk.DRW2NQNF.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-listing/jb-listing.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";

// src/components/jb-listing/jb-listing.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_listing_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
.kicker p {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.kicker p {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 22px;
  line-height: 28px;
}
@media (min-width: 1281px) {
  .kicker p {
    font-size: 28px;
    line-height: 40px;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

.listing.animate::after {
  background: #141e55;
  right: 100%;
  transition-delay: 0s;
  transition-duration: 0.8s;
  transition-timing-function: ease-in-out;
}
.listing.animate:hover::before {
  background: rgba(20, 30, 85, 0.2);
  bottom: 0;
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
}
.listing.animate:hover::after {
  background: #141e55;
  left: 0;
  right: 0;
  transition-delay: 0s;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.kicker {
  margin-bottom: 8px;
}
.kicker p {
  color: #141e55;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1px;
  opacity: 0.6;
  text-transform: uppercase;
  font-size: 11px;
  font-size: 0.6875rem;
  line-height: 23px;
  line-height: 1.45rem;
}

jb-section-headline {
  margin-bottom: 60px;
}
jb-section-headline.center_md_down {
  --headline-text-align: center;
}
@media (min-width: 960px) {
  jb-section-headline.center_md_down {
    --headline-text-align: left;
  }
}
@media (min-width: 1281px) {
  jb-section-headline {
    margin-bottom: 68px;
  }
}
.kicker + jb-section-headline {
  --headline-text-transform: none;
  --headline-letter-spacing: 0;
  --headline-text-align: left;
  margin-bottom: 30px;
}
@media (min-width: 1281px) {
  .kicker + jb-section-headline {
    margin-bottom: 34px;
  }
}

jb-listing-filter {
  padding-bottom: 23px;
}

.filters {
  padding-bottom: 24px;
}

.list {
  position: relative;
  z-index: 1;
  font-size: 0;
  font-size: 0;
}
.list.show-load-more + .m-listing__btn-wrap .button {
  display: inline-block;
}

jb-button-panel {
  margin-top: 24px;
}
@media (min-width: 1281px) {
  jb-button-panel {
    margin-top: 32px;
  }
}

jb-event-list-item:not(:last-child),
jb-listing-item:not(:last-child),
jb-news-list-item:not(:last-child) {
  margin-bottom: 20px;
}

jb-event-list-item:not(:defined),
jb-news-list-item:not(:defined) {
  display: block;
  height: 70px;
}`;

// src/components/jb-listing/jb-listing.ts
var JbListing = class extends ChangesBackgroundColor(Spaceable(LitElement)) {
  constructor() {
    super(...arguments);
    this.type = "listing";
    this._expanded = false;
    this._filteredItems = [];
  }
  updated(_changedProperties) {
    super.updated(_changedProperties);
    this._hideButtonPanelIfNotNeeded();
    if (!_changedProperties.has("_filteredItems")) {
      this._refreshItemsList();
    }
  }
  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    this._filteredItems = this.items;
    this._refreshItemsList();
    this._stripUselessFilters(this.filters);
    if (this.type === "news") {
      import("../components/jb-news-list-item/jb-news-list-item.js").then();
    }
    if (this.type === "event") {
      import("../components/jb-event-list-item/jb-event-list-item.js").then();
    }
  }
  _countItemsMatchingFilters(filters) {
    const { length } = this._getMatchingItems(filters);
    return length;
  }
  _getActiveFilter(filterAspect) {
    const activeFilter = this.filters?.find((filter) => filter.filterAspect === filterAspect);
    return activeFilter.activeValue ? activeFilter.activeValue : activeFilter.options[0].value;
  }
  _getActiveFilters() {
    const activeFilters = /* @__PURE__ */ new Map();
    this.filters?.forEach(
      (filter) => activeFilters.set(filter.filterAspect, filter.activeValue ? filter.activeValue : filter.options[0].value)
    );
    return activeFilters;
  }
  _getContentSpacingProperties() {
    const type = this.headline ? "list" : "list-without-heading";
    const cols = this.variant === "narrow" ? 6 : void 0;
    return { colsMdUp: cols, type };
  }
  _getMatchingItems(filters) {
    if (this.items === null) {
      return void 0;
    }
    return this.items?.filter((item) => {
      let compliesWithAllFilters = true;
      [...filters.keys()].forEach((filterAspect) => {
        const value = filters.get(filterAspect);
        if (value === "" || value === "__all" || value === void 0) {
          return;
        }
        if (!this._matchesFilterValue(item, filterAspect, value)) {
          compliesWithAllFilters = false;
        }
      });
      return compliesWithAllFilters;
    });
  }
  _handleExpandButtonClick(event) {
    event.preventDefault();
    this._expanded = true;
  }
  _handleFilterChange(event) {
    this._setActiveFilter(event.detail.filterAspect, event.detail.newValue);
    this._expanded = false;
    this.requestUpdate();
  }
  _hideButtonPanelIfNotNeeded() {
    if (!this._buttonPanel) {
      return;
    }
    if (!this._buttonsSlot || !this._buttonsSlot.assignedElements().length) {
      this._buttonPanel.style.display = "none";
    } else {
      this._buttonPanel.style.removeProperty("display");
    }
  }
  _matchesFilterValue(item, filterAspect, value) {
    return !!item.filterValues?.find(
      (filterValue) => filterValue.filterAspect === filterAspect && filterValue.value === value
    );
  }
  _refreshItemsList() {
    if (this.items.length === 0) {
      return;
    }
    const matchingItems = this.filters === void 0 || this.filters.length === 0 ? this.items : this._getMatchingItems(this._getActiveFilters());
    const itemsToBeShown = this._expanded || !this.truncateAmount ? matchingItems : matchingItems.slice(0, this.truncateAmount);
    this._filteredItems = itemsToBeShown;
    if (matchingItems?.length > 0 && this.truncateAmount && !this._expanded && matchingItems?.length <= this.truncateAmount) {
      this._expanded = true;
    }
  }
  _setActiveFilter(filterAspect, value) {
    const matchingFilter = this.filters?.find((filter) => filter.filterAspect === filterAspect);
    if (!matchingFilter) {
      return;
    }
    matchingFilter.activeValue = value;
  }
  _showExpandButton() {
    if (!this.items || !this.showMoreLabel || !this.truncateAmount || this.truncateAmount < 1 || this._expanded) {
      return false;
    }
    return this.items.length > this.truncateAmount;
  }
  _stripUselessFilters(filters) {
    if (filters === void 0 || this.items.length === 0) {
      return void 0;
    }
    const usefulFilters = [];
    const actualParentFilters = /* @__PURE__ */ new Map();
    filters.forEach((filter) => {
      const usefulFilter = { ...filter };
      const usefulFilterOptions = [];
      filter.options.forEach((option) => {
        let optionIsUseful;
        if (option.value === "__all") {
          optionIsUseful = true;
        } else {
          const simulateFilters = new Map(JSON.parse(JSON.stringify(Array.from(actualParentFilters))));
          simulateFilters.set(filter.filterAspect, option.value);
          const filterOptionMatchCount = this._countItemsMatchingFilters(
            simulateFilters
          );
          optionIsUseful = !(filterOptionMatchCount === 0);
        }
        if (!optionIsUseful) {
          return;
        }
        usefulFilterOptions.push(option);
      });
      usefulFilter.options = usefulFilterOptions;
      actualParentFilters.set(filter.filterAspect, this._getActiveFilter(filter.filterAspect));
      if (usefulFilter.options.length < 1) {
        this._setActiveFilter(filter.filterAspect, void 0);
        return;
      }
      const activeUsefulOption = usefulFilter.options.find(
        (option) => option.value === this._getActiveFilter(filter.filterAspect)
      );
      if (!activeUsefulOption) {
        this._setActiveFilter(filter.filterAspect, usefulFilter.options[0].value);
      }
      usefulFilters.push(usefulFilter);
    });
    return usefulFilters;
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "listing"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    const effectiveFilters = this._stripUselessFilters(this.filters);
    return html`
      <div class="listing">
        ${this.kicker ? html`<div class="kicker"><p>${this.kicker}</p></div>` : nothing}
        ${this.headline && html` <jb-section-headline
          .headline=${this.headline}
          .languageBadge=${this.languageBadge}
          class="${classMap({ center_md_down: !!(this.headlineAlign || this.kicker) })}"
        ></jb-section-headline>`}
        ${effectiveFilters ? html`
              <div class="filters">
                ${effectiveFilters.map(
      (filter) => html` <jb-listing-filter
                      @jb-listing-filter:change=${this._handleFilterChange}
                      .activeValue=${filter.activeValue || filter.options[0].value}
                      .filterAspect=${filter.filterAspect}
                      .options=${filter.options}
                    ></jb-listing-filter>`
    )}
              </div>
            ` : nothing}

        <div class="list">
          ${this._filteredItems && this.type === "news" ? this._filteredItems.map(
      (newsItem) => html`
                  <jb-news-list-item
                    .date=${newsItem.date}
                    .filterValues=${newsItem.filterValues}
                    .link=${newsItem.link}
                    .newsTitle=${newsItem.newsTitle}
                    .languages=${newsItem.languages}
                    @jb-link:clicked=${this._tracking}
                  ></jb-news-list-item>
                `
    ) : nothing}
          ${this._filteredItems && this.type === "listing" ? this._filteredItems.map(
      (listingItem) => html`
                  <jb-listing-item
                    .date=${listingItem.date}
                    .file=${listingItem.file}
                    .text=${listingItem.text}
                    .type=${listingItem.type}
                    .link=${listingItem.link}
                    @jb-link:clicked=${this._tracking}
                  ></jb-listing-item>
                `
    ) : nothing}
          ${this._filteredItems && this.type === "event" ? this._filteredItems.map(
      (eventItem) => html`
                  <jb-event-list-item
                    .addToCalendarLabel=${eventItem.addToCalendarLabel}
                    .date=${eventItem.date}
                    .icsLink=${eventItem.icsLink}
                    .filterValues=${eventItem.filterValues}
                    .link=${eventItem.link}
                    .location=${eventItem.location}
                    .time=${eventItem.time}
                    .text=${eventItem.text}
                    @jb-link:clicked=${this._tracking}
                  ></jb-event-list-item>
                `
    ) : nothing}
        </div>
        ${this._showExpandButton() ? html`
              <jb-button-panel .align=${"center"}>
                <jb-button
                  @click=${this._handleExpandButtonClick}
                  .link=${{ href: "#" }}
                  text=${ifDefined(this.showMoreLabel)}
                ></jb-button>
              </jb-button-panel>
            ` : nothing}
        <jb-button-panel data-buttons>
          <slot name="buttons"></slot>
        </jb-button-panel>
      </div>
    `;
  }
};
JbListing.styles = unsafeCSS(jb_listing_default);
__decorateClass([
  property({ type: Array })
], JbListing.prototype, "filters", 2);
__decorateClass([
  property()
], JbListing.prototype, "headline", 2);
__decorateClass([
  property()
], JbListing.prototype, "headlineAlign", 2);
__decorateClass([
  property({ type: Array })
], JbListing.prototype, "items", 2);
__decorateClass([
  property()
], JbListing.prototype, "kicker", 2);
__decorateClass([
  property()
], JbListing.prototype, "languageBadge", 2);
__decorateClass([
  property()
], JbListing.prototype, "showMoreLabel", 2);
__decorateClass([
  property({ type: Number })
], JbListing.prototype, "truncateAmount", 2);
__decorateClass([
  property()
], JbListing.prototype, "type", 2);
__decorateClass([
  property()
], JbListing.prototype, "variant", 2);
__decorateClass([
  query("jb-button-panel[data-buttons]")
], JbListing.prototype, "_buttonPanel", 2);
__decorateClass([
  query('slot[name="buttons"]')
], JbListing.prototype, "_buttonsSlot", 2);
__decorateClass([
  state()
], JbListing.prototype, "_expanded", 2);
__decorateClass([
  state()
], JbListing.prototype, "_filteredItems", 2);
JbListing = __decorateClass([
  customElement("jb-listing")
], JbListing);

export {
  JbListing
};
