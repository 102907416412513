import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-i18n-data/jb-i18n-data.ts
import { LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";
var JbI18nData = class extends LitElement {
  static find() {
    return document.querySelector("jb-i18n-data") || void 0;
  }
  getI18nDataCountriesForRegion(region) {
    return this.i18nData?.regions.find((obj) => obj.regionUid.toString() === region.toString())?.countries;
  }
  getI18nDataLanguageOptionsForCountry(country) {
    const languageOptions = [];
    this.i18nData?.regions.some((regionData) => {
      const foundData = regionData.countries.find(
        (obj) => obj.countryIsoCode.toString() === country.toString()
      )?.languageOptions;
      if (foundData) {
        foundData.forEach((languageOption) => {
          languageOptions.push(languageOption);
        });
        return null;
      }
      return null;
    });
    return languageOptions;
  }
  getI18nDataRegions() {
    return this.i18nData?.regions;
  }
  getLanguageByIsoCode(isoCode) {
    return this.i18nData?.globalLanguages[isoCode];
  }
  getMarketLabel(marketId, languageId) {
    const matchingMarket = this.i18nData?.markets.find((market) => market.marketUid === marketId);
    if (!matchingMarket) {
      return void 0;
    }
    return matchingMarket.labels[languageId ? `${languageId}` : "current"] || void 0;
  }
  render() {
    return "";
  }
};
__decorateClass([
  property({ type: Object })
], JbI18nData.prototype, "i18nData", 2);
JbI18nData = __decorateClass([
  customElement("jb-i18n-data")
], JbI18nData);

export {
  JbI18nData
};
