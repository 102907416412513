// src/assets/scripts/JB.ts
import { nothing, unsafeCSS } from "lit";
var JBCookie = class _JBCookie {
  static deleteCookie(name) {
    _JBCookie.setCookie(name, "", -1);
  }
  static getCookie(name) {
    const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  }
  static getI18nCookieData() {
    const cookieValue = this.getCookie("i18n") || "||0||";
    const parts = cookieValue.split("|");
    return {
      country: parts[1],
      language: parts[2],
      languageIso2: parts[3],
      market: parts[4],
      region: parts[0]
    };
  }
  static setCookie(name, value, days = 182) {
    const d = /* @__PURE__ */ new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1e3 * days);
    document.cookie = name + "=" + value + ";secure;SameSite=Strict;path=/;expires=" + d.toUTCString();
  }
  static setI18nCookieData(insertData) {
    const cookieData = _JBCookie.getI18nCookieData();
    Object.keys(insertData).forEach((key) => {
      cookieData[key] = insertData[key];
    });
    const cookieValue = (cookieData.region || "") + "|" + (cookieData.country || "") + "|" + (cookieData.language.toString() || "") + "|" + (cookieData.languageIso2 || "") + "|" + (cookieData.market || "");
    this.setCookie("i18n", cookieValue, 182);
  }
};
function throttle(fn, ms = 300) {
  let prev = 0;
  return (...args) => {
    const now = (/* @__PURE__ */ new Date()).getTime();
    if (now - prev > ms) {
      prev = now;
      return fn(...args);
    }
    return null;
  };
}
function debounce(fn, ms = 300) {
  let timeoutId;
  return function(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}
function respondTo(map) {
  if (window.matchMedia("(min-width: 1437px)").matches && map.xl !== void 0) {
    return map.xl;
  }
  if (window.matchMedia("(min-width: 1281px)").matches && map.lg !== void 0) {
    return map.lg;
  }
  if (window.matchMedia("(min-width: 960px)").matches && map.md !== void 0) {
    return map.md;
  }
  if (window.matchMedia("(min-width: 600px)").matches && map.sm !== void 0) {
    return map.sm;
  }
  return map.xs;
}
async function awaitForEach(array, cb) {
  for (let index = 0; index < array.length; index += 1) {
    await cb(array[index], index, array);
  }
}
function isPartiallyInViewPort(element, offset = 0) {
  const distanceFromTop = element.getBoundingClientRect().top + offset;
  const windowHeight = window.innerHeight;
  const heightOfElement = element.clientHeight;
  return distanceFromTop < windowHeight && distanceFromTop + heightOfElement > 0;
}
function dispatchEvent(name, obj) {
  const evt = new CustomEvent(name, obj);
  document.body.dispatchEvent(evt);
}
function nlToBr(input) {
  return input.replace(/\r\n|\r|\n/g, "<br>");
}
function mergeDeep(target, ...sources) {
  const isObject = (item) => item && typeof item === "object" && !Array.isArray(item);
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    });
  }
  return mergeDeep(target, ...sources);
}
function scrollToTop(element) {
  const speed = 200;
  const scrollStartY = window.scrollY || document.documentElement.scrollTop || element?.scrollTop || 0;
  let currentTimestamp = 0;
  const scrollDistanceInPx = Math.abs(scrollStartY);
  const scrollDistance = 0 - scrollStartY;
  const suggestedAnimationDuration = scrollDistanceInPx / speed;
  const animationDuration = Math.max(0.1, Math.min(suggestedAnimationDuration, 0.8));
  function easeOutSine(t) {
    return Math.sin(t * (Math.PI / 2));
  }
  function scroll(x, y) {
    if (element) {
      element.scrollTo(x, y);
    } else {
      window.scrollTo(x, y);
    }
  }
  function tick() {
    const timeIncrement = 1 / 60;
    currentTimestamp += timeIncrement;
    const time = currentTimestamp / animationDuration;
    const position = easeOutSine(time);
    if (time < 1) {
      window.requestAnimationFrame(tick);
      const x = 0;
      const scrollIncrement = scrollDistance * position;
      const nextYPosition = scrollStartY + scrollIncrement;
      scroll(x, nextYPosition);
    } else {
      scroll(0, 0);
    }
  }
  window.requestAnimationFrame(tick);
}
var ifTruthy = (value) => value || nothing;
function getScrollBarWidth() {
  const el = document.createElement("div");
  el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
  document.body.appendChild(el);
  const width = el.offsetWidth - el.clientWidth;
  el.remove();
  return width;
}
function recenterNavItems() {
  const scrollBarWidth = getScrollBarWidth();
  document.body.style.setProperty("right", "0");
  if (document.body.style.overflow === "hidden" && document.body.clientHeight > window.innerHeight) {
    document.body.style.setProperty("--compensate-scrollbar", scrollBarWidth + "px");
  } else {
    document.body.style.setProperty("--compensate-scrollbar", "0px");
  }
}
function getEventElement(evt, nodeQuery) {
  return evt.composedPath().find((elm) => elm.nodeName === nodeQuery);
}
function interserctionObserver(target, options, callback) {
  const observer = new IntersectionObserver(callback, options);
  observer.observe(target);
}
function applyStyles(element, styles) {
  if (!styles)
    return;
  const rootElm = element.getRootNode().nodeType === 11 ? element.getRootNode() : document.head;
  if (rootElm.querySelector(`style[data-element=${element.nodeName}]`))
    return;
  const styleElm = document.createElement("style");
  const stylesResult = unsafeCSS(styles);
  styleElm.appendChild(document.createTextNode(stylesResult.cssText));
  styleElm.dataset.element = element.nodeName;
  rootElm.appendChild(styleElm);
}
function smoothScroll(element, duration = 600) {
  const start = window.scrollY;
  const end = element.getBoundingClientRect().top + window.scrollY;
  const distance = end - start;
  let startTimestamp = null;
  const easeInOutQuad = (t, b, c, d) => {
    if ((t /= d / 2) < 1)
      return c / 2 * t * t + b;
    return -c / 2 * (--t * (t - 2) - 1) + b;
  };
  const animate = () => {
    if (!startTimestamp)
      startTimestamp = performance.now();
    const now = performance.now();
    const elapsed = now - startTimestamp;
    const progress = elapsed / duration;
    const easedY = easeInOutQuad(progress, start, distance, 1);
    window.scrollTo(0, easedY);
    if (progress < 1) {
      window.requestAnimationFrame(animate);
    }
  };
  window.requestAnimationFrame(animate);
}
function getAllParents(element) {
  let curNode = element;
  const elms = [];
  while (curNode) {
    if (curNode.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
      if (curNode instanceof ShadowRoot) {
        if (curNode.host.assignedSlot) {
          elms.push(curNode.host.assignedSlot);
          curNode = curNode.host.assignedSlot.parentNode ?? void 0;
        } else {
          elms.push(curNode.host);
          curNode = curNode.host.parentNode ?? void 0;
        }
      }
    } else {
      elms.push(curNode);
      curNode = curNode.parentNode ?? void 0;
    }
  }
  return elms;
}
function querySelectorAllShadow(selector, startNode = document) {
  if (selector === "")
    return [];
  const nodes = [...startNode.querySelectorAll(selector)];
  const nodeIterator = document.createNodeIterator(startNode, Node.ELEMENT_NODE);
  let currentNode;
  while (currentNode = nodeIterator.nextNode()) {
    if (currentNode.shadowRoot) {
      nodes.push(...querySelectorAllShadow(selector, currentNode.shadowRoot));
    }
  }
  return nodes;
}

export {
  JBCookie,
  throttle,
  debounce,
  respondTo,
  awaitForEach,
  isPartiallyInViewPort,
  dispatchEvent,
  nlToBr,
  mergeDeep,
  scrollToTop,
  ifTruthy,
  getScrollBarWidth,
  recenterNavItems,
  getEventElement,
  interserctionObserver,
  applyStyles,
  smoothScroll,
  getAllParents,
  querySelectorAllShadow
};
