import {
  Spaceable
} from "./chunk.SHDHDUWS.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-news-page-hero/jb-news-page-hero.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";

// src/components/jb-news-page-hero/jb-news-page-hero.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_news_page_hero_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
.intro-section ul .languages-label, h5 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 700;
}

.intro-section jb-date, .intro, h1 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

h1 {
  font-size: 34px;
  line-height: 44px;
}
@media (min-width: 1281px) {
  h1 {
    font-size: 50px;
    line-height: 70px;
  }
}

h5 {
  font-size: 17px;
  line-height: 30px;
}
@media (min-width: 1281px) {
  h5 {
    font-size: 19px;
    line-height: 34px;
  }
}

.intro {
  font-size: 19px;
  line-height: 28px;
}
@media (min-width: 1281px) {
  .intro {
    font-size: 22px;
    line-height: 34px;
  }
}

.intro-section jb-date {
  color: rgba(var(--rgb-primary, 20, 30, 85), var(--text-opacity, 0.8));
  font-size: 16px;
  line-height: 26px;
}

.intro-section ul .languages-label {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}
@media (min-width: 1281px) {
  :host {
    padding: 36px 0 20px;
  }
}

.title {
  --text-opacity: 1;
}

.intro-section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 24px;
}
@media (min-width: 960px) {
  .intro-section {
    padding: 0 0 37px;
  }
}
.intro-section jb-date {
  --rgb-primary: 20, 30, 85;
  --text-opacity: 0.4;
  text-transform: uppercase;
}
.intro-section ul {
  align-items: center;
  display: flex;
  list-style-type: none;
}
.intro-section ul .languages-label {
  color: rgba(20, 30, 85, 0.4);
  display: none;
  padding-right: 15px;
  text-transform: uppercase;
}
@media (min-width: 1281px) {
  .intro-section ul .languages-label {
    display: block;
  }
}
.intro-section ul jb-language-item {
  padding: 0 15px;
  padding: 0 0.9375rem;
  --text-opacity: 1;
  position: relative;
}
.intro-section ul jb-language-item:hover {
  --text-opacity: 0.4;
}
.intro-section ul jb-language-item::after {
  width: 3px;
  width: 0.1875rem;
  height: 3px;
  height: 0.1875rem;
  right: -2px;
  right: -0.125rem;
  background-color: #141e55;
  border-radius: 100%;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.intro-section ul jb-language-item:first-of-type {
  padding-left: 0;
}
.intro-section ul jb-language-item:last-of-type {
  padding-right: 0;
}
.intro-section ul jb-language-item:last-of-type::after {
  display: none;
}

jb-breadcrumbs {
  display: block;
  margin-bottom: 26px;
  text-align: center;
  line-height: 36px;
  line-height: 2.25rem;
}
@media (min-width: 600px) {
  jb-breadcrumbs {
    text-align: left;
  }
}
@media (min-width: 960px) {
  jb-breadcrumbs {
    margin-bottom: 40px;
  }
}

.toolbar {
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  position: relative;
  width: 100%;
}
.toolbar jb-article-share-bar {
  margin-left: auto;
}
@media (min-width: 960px) {
  .toolbar {
    margin-bottom: 32px;
  }
}

h1 {
  margin-bottom: 20px;
}

.intro {
  margin-bottom: 32px;
}`;

// src/components/jb-news-page-hero/jb-news-page-hero.ts
var JbNewsPageHero = class extends Spaceable(LitElement) {
  _getContentSpacingProperties() {
    return { type: "no-vertical-space" };
  }
  render() {
    return html`<article>
      ${this.breadcrumbs && html`
          <jb-breadcrumbs>
            ${this.breadcrumbs.backlink && html`<jb-breadcrumbs-back-link
              .link=${this.breadcrumbs.backlink.link}
              .text=${this.breadcrumbs.backlink.text}
              slot="backlink"
            ></jb-breadcrumbs-back-link>`}
            ${this.breadcrumbs.items?.map(
      (breadcrumb) => html`<jb-breadcrumbs-item
                  .link=${breadcrumb.link}
                  .text=${breadcrumb.text}
                  slot="items"
                ></jb-breadcrumbs-item>`
    )}
          </jb-breadcrumbs>
        `}
      <header>
        <div class="intro-section">
          <jb-date date=${this.date}></jb-date>
          ${this.languages?.length && html`<ul>
              <li class="languages-label">${this.languagesLabel}:</li>
              ${this.languages?.map(
      (language) => html`<jb-language-item
                    .text=${language.text}
                    .link=${language.link}
                  ></jb-language-item>`
    )}
            </ul>`}
        </div>
      </header>
      <div class="title">
        ${this.subline && html`<h5>${this.subline}</h5>`}
        <h1>${this.headline}</h1>
      </div>
      ${this.shareBarLabel && html`<div class="toolbar">
          ${this.mailSubject && this.shareBarLabel && this.sharingUrl && html`
            <jb-article-share-bar
              .articleTitle=${this.headline}
              .mailSubject=${this.mailSubject}
              .title=${this.shareBarLabel}
              .url=${this.sharingUrl}
            ></jb-article-share-bar>
          `}
        </div>`}
      <div class="intro">${this.intro}</div>
      </div>
      <slot></slot>
    </article>`;
  }
};
JbNewsPageHero.styles = unsafeCSS(jb_news_page_hero_default);
__decorateClass([
  property({ type: Object })
], JbNewsPageHero.prototype, "breadcrumbs", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "date", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "headline", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "intro", 2);
__decorateClass([
  property({ type: Array })
], JbNewsPageHero.prototype, "languages", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "languagesLabel", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "mailSubject", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "shareBarLabel", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "sharingUrl", 2);
__decorateClass([
  property()
], JbNewsPageHero.prototype, "subline", 2);
JbNewsPageHero = __decorateClass([
  customElement("jb-news-page-hero")
], JbNewsPageHero);

export {
  JbNewsPageHero
};
