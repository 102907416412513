import "../../chunks/chunk.7VONTVYH.js";

// src/assets/scripts/FallbackTracking.ts
if (window.adobeDataLayer === void 0) {
  window.adobeDataLayer = [];
}
function convertToAcdl(event) {
  const eventDetails = event.detail;
  const acdl = {
    eventInfo: {},
    event: {}
  };
  if (eventDetails.event) {
    acdl.event = eventDetails.event;
    delete eventDetails.event;
  }
  acdl.eventInfo = eventDetails;
  return acdl;
}
document.body.addEventListener("jb-tracking", (event) => {
  window.adobeDataLayer.push(convertToAcdl(event));
});
function isOutboundLink(linkUrl) {
  const outboundLinkRegex = new RegExp(`^(https?://)(?!${window.location.hostname}).+`);
  return outboundLinkRegex.test(linkUrl);
}
function isDownloadLink(url) {
  const downloadFileTypes = "doc|docx|eps|xls|ppt|pptx|pdf|xlsx|tab|csv|zip|txt|vsd|vxd|xml|js|css|rar|exe|wma|mov|avi|wmv|mp3|wav|m4v|ics|msg|ical|ics|ifb|icalendar";
  const regExp = new RegExp("\\.(?:" + downloadFileTypes + ")($|&|\\?)");
  return regExp.test(url);
}
function isMailtoLink(url) {
  return url.slice(0, 7) === "mailto:";
}
function isPhoneLink(url) {
  return url.slice(0, 4) === "tel:";
}
function buildComponentName(url) {
  if (isOutboundLink(url))
    return "generic-outbound-link-tracking";
  if (isDownloadLink(url))
    return "generic-download-link-tracking";
  if (isMailtoLink(url))
    return "generic-email-link-tracking";
  if (isPhoneLink(url))
    return "generic-phone-link-tracking";
  return "generic-internal-link-tracking";
}
function buildEvent(url) {
  if (isDownloadLink(url))
    return "ce_download";
  return "ce_interaction";
}
function buildInteractionEffect(url) {
  if (isDownloadLink(url))
    return "download";
  if (isMailtoLink(url))
    return "open_email";
  if (isPhoneLink(url))
    return "start_phone_call";
  return "navigation";
}
function buildInteractionElement(url) {
  if (isOutboundLink(url))
    return "External Links";
  if (isDownloadLink(url))
    return "Download Links";
  if (isMailtoLink(url))
    return "Mailto Links";
  if (isPhoneLink(url))
    return "Phone Links";
  return "Internal Links";
}
document.addEventListener("jb-link:clicked", (event) => {
  const eventDetails = event.detail;
  const url = eventDetails.linkTag.href.trim();
  if (eventDetails.noGenericLinkTracking)
    return;
  document.body.dispatchEvent(
    new CustomEvent("jb-tracking", {
      detail: {
        component_name: buildComponentName(url),
        event: buildEvent(url),
        event_version: "v2",
        interaction_effect: buildInteractionEffect(url),
        interaction_element: buildInteractionElement(url),
        interaction_result: new URL(url).href,
        interaction_text: eventDetails.linkTag.innerText,
        interaction_type: "click"
      },
      bubbles: true,
      composed: true
    })
  );
});
var { adobeDataLayer } = window;
export {
  adobeDataLayer
};
