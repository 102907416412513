import {
  Spaceable
} from "./chunk.SHDHDUWS.js";
import {
  getAllParents,
  scrollToTop
} from "./chunk.SYAPDNOX.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-article-back-to-top/jb-article-back-to-top.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";

// src/components/jb-article-back-to-top/jb-article-back-to-top.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_article_back_to_top_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:root {
  display: block;
}

.wrapper {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2 * 6.68%);
  justify-content: center;
  padding-block-end: 60px;
  position: relative;
}
@media (min-width: 600px) and (max-width: 959px) {
  .wrapper {
    width: calc(100% - 2 * 8.35%);
  }
}
@media (min-width: 1437px) {
  .wrapper {
    max-width: 1436px;
  }
  .wrapper:not(.filtered-list) {
    width: 100%;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1436px) {
  .wrapper {
    margin: 0 6.955%;
    max-width: calc(100% - 6.955% - 6.955%);
  }
}
@media (min-width: 1281px) {
  .wrapper {
    padding-block-end: 68px;
  }
}
.wrapper jb-button-tertiary {
  display: inline-block;
  position: relative;
}

@media print {
  :host {
    display: none;
  }
}`;

// src/components/jb-article-back-to-top/jb-article-back-to-top.ts
var JbArticleBackToTop = class extends Spaceable(LitElement) {
  _click(e) {
    e.preventDefault();
    const elms = getAllParents(this);
    let scrolled = false;
    elms.forEach((elm) => {
      if (scrolled)
        return;
      const element = elm;
      if (element.clientHeight + 20 < element.scrollHeight) {
        scrollToTop(element);
        scrolled = true;
      }
    });
  }
  render() {
    return html`
      <div class="wrapper">
        <div>
          <jb-button-tertiary
            @click=${this._click}
            arrowDirection="up"
            >${this.text}</jb-button-tertiary
          >
        </div>
      </div>
    `;
  }
};
JbArticleBackToTop.styles = unsafeCSS(jb_article_back_to_top_default);
__decorateClass([
  property()
], JbArticleBackToTop.prototype, "text", 2);
JbArticleBackToTop = __decorateClass([
  customElement("jb-article-back-to-top")
], JbArticleBackToTop);

export {
  JbArticleBackToTop
};
