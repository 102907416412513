import {
  Linkable
} from "./chunk.VPBTB6HE.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-breadcrumbs-back-link/jb-breadcrumbs-back-link.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";

// src/components/jb-breadcrumbs-back-link/jb-breadcrumbs-back-link.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_breadcrumbs_back_link_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
a {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 700;
}

a {
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

a {
  align-items: center;
  display: inline-flex;
  flex-direction: row-reverse;
  gap: var(--spacing-3xs);
  text-decoration: none;
}

svg {
  display: inline-block;
  fill: rgb(var(--rgb-primary, 20, 30, 85));
  flex-shrink: 0;
}`;

// src/components/jb-breadcrumbs-back-link/jb-breadcrumbs-back-link.ts
var JbBreadcrumbsBackLink = class extends Linkable(LitElement) {
  render() {
    return html`
      <a data-linkable>
        <span>${this.text}</span>
        <svg
          width="15"
          height="10"
          viewbox="0 0 15 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill-rule="evenodd">
            <path
              d="M.978 5.37a.596.596 0 0 1 0-.835L4.811.656a.58.58 0 0 1 .826 0 .596.596 0 0 1 0 .836l-3.42 3.46 3.42 3.46a.596.596 0 0 1 0 .837.58.58 0 0 1-.826 0L.978 5.37z"
            ></path>
            <path d="M15.6 5.583h-14v-1h14z"></path>
          </g>
        </svg>
      </a>
    `;
  }
};
JbBreadcrumbsBackLink.styles = unsafeCSS(jb_breadcrumbs_back_link_default);
__decorateClass([
  property()
], JbBreadcrumbsBackLink.prototype, "text", 2);
JbBreadcrumbsBackLink = __decorateClass([
  customElement("jb-breadcrumbs-back-link")
], JbBreadcrumbsBackLink);

export {
  JbBreadcrumbsBackLink
};
