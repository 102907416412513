import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/icons/jb-burger-icon/jb-burger-icon.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";

// src/components/icons/jb-burger-icon/jb-burger-icon.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_burger_icon_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

.burger-icon {
  cursor: pointer;
  width: 40px;
}
.burger-icon .bar-wrap {
  height: 1px;
  margin: 7px auto;
}
.burger-icon .bar-wrap:first-child {
  margin-bottom: 9px;
  margin-top: 7px;
}
@media (min-width: 600px) {
  .burger-icon .bar-wrap:first-child {
    margin-top: 0;
  }
}
.burger-icon .bar-wrap:last-child {
  margin-top: 9px;
}
@media (min-width: 600px) {
  .burger-icon .bar-wrap {
    margin: 0;
  }
}
.burger-icon .bar {
  border-bottom: 1px solid rgb(var(--rgb-primary, 255, 255, 255));
  height: 1px;
  transition: var(--bar-transition);
}
.burger-icon .bottom,
.burger-icon .top {
  transition: transform 0.2s linear;
}
.burger-icon:not(.open):hover .bottom {
  transform: translateY(-2px);
}
.burger-icon:not(.open):hover .top {
  transform: translateY(2px);
}
.burger-icon.open .bar-wrap {
  width: 40px;
}
.burger-icon.open .bar {
  margin: 0 auto;
}
.burger-icon.open .bottom,
.burger-icon.open .top {
  transition: width 0.3s ease-in-out, transform 0.2s linear;
  width: 35px;
}
.burger-icon.open .top {
  background: rgb(var(--rgb-primary, 255, 255, 255));
  position: relative;
  transform: translateY(10px) rotateZ(-45deg);
  z-index: 100;
}
@media (min-width: 600px) {
  .burger-icon.open .top {
    transform: translateY(11px) rotateZ(-45deg);
  }
}
.burger-icon.open .bottom {
  background: rgb(var(--rgb-primary, 255, 255, 255));
  position: relative;
  transform: translateY(-10px) rotateZ(45deg);
  z-index: 100;
}
@media (min-width: 600px) {
  .burger-icon.open .bottom {
    transform: translateY(-9px) rotateZ(45deg);
  }
}
.burger-icon.open .middle {
  transition: none;
  width: 0;
}
.burger-icon.open:hover {
  backface-visibility: hidden;
  perspective: 1000px;
}
.burger-icon.open:hover .top,
.burger-icon.open:hover .bottom {
  perspective: 1000px;
  transition: width 0.2s ease-in-out, transform 0.2s linear;
  width: 28px;
}
@media (min-width: 600px) and (max-width: 959px) {
  .burger-icon.open {
    height: 35px;
  }
}`;

// src/components/icons/jb-burger-icon/jb-burger-icon.ts
var JbBurgerIcon = class extends LitElement {
  constructor() {
    super(...arguments);
    this.isOpen = false;
  }
  firstUpdated() {
    this.addEventListener("click", (e) => {
      e.preventDefault();
      this.dispatchEvent(new CustomEvent("burger-icon:clicked", { bubbles: true, composed: true }));
    });
  }
  render() {
    return html`
      <div
        class=${classMap({
      "burger-icon": true,
      open: this.isOpen || false
    })}
      >
        <div class="bar-wrap">
          <div class="bar top"></div>
        </div>
        <div class="bar-wrap">
          <div class="bar middle"></div>
        </div>
        <div class="bar-wrap">
          <div class="bar bottom"></div>
        </div>
      </div>
    `;
  }
};
JbBurgerIcon.styles = unsafeCSS(jb_burger_icon_default);
__decorateClass([
  property({ type: Boolean })
], JbBurgerIcon.prototype, "isOpen", 2);
JbBurgerIcon = __decorateClass([
  customElement("jb-burger-icon")
], JbBurgerIcon);

export {
  JbBurgerIcon
};
