// src/assets/scripts/Client.ts
function getViewport() {
  return {
    height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  };
}
function isMobileVP() {
  return window.matchMedia("(max-width: 599px)").matches;
}
function isTabletVP() {
  return window.matchMedia("(min-width: 600px) and (max-width: 959px)").matches;
}
function isSizeMdDown() {
  return window.matchMedia("(max-width: 1280px)").matches;
}
function isSizeSmUp() {
  return window.matchMedia("(min-width: 600px)").matches;
}
function isTouchDevice() {
  return window.matchMedia("(hover: none)").matches;
}
function isMobile() {
  return isSizeMdDown() || isTouchDevice();
}
function getScrollPosition() {
  return document.body.scrollTop || document.documentElement.scrollTop;
}
function isInViewport(element) {
  if (!element)
    return false;
  const rect = element.getBoundingClientRect();
  return rect.top >= -1;
}

export {
  getViewport,
  isMobileVP,
  isTabletVP,
  isSizeMdDown,
  isSizeSmUp,
  isTouchDevice,
  isMobile,
  getScrollPosition,
  isInViewport
};
