import {
  Spaceable
} from "./chunk.SHDHDUWS.js";
import {
  Initiable
} from "./chunk.6DHNIVN5.js";
import {
  jbLinkClickedEventToJbTrackingEvent
} from "./chunk.VPBTB6HE.js";
import {
  JBCookie
} from "./chunk.SYAPDNOX.js";
import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-info-box/jb-info-box.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

// src/components/jb-info-box/jb-info-box.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_info_box_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
h2 {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 300;
}

h2 {
  font-size: 26px;
  line-height: 35px;
}
@media (min-width: 1281px) {
  h2 {
    font-size: 31px;
    line-height: 42px;
  }
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

:host {
  display: block;
}

h2 {
  margin-bottom: var(--spacing-xs);
}

div {
  border: 1px solid rgba(20, 30, 85, 0.2);
  box-sizing: border-box;
  padding: var(--spacing-m);
}

.in-text {
  background-color: #f7f7f2;
  border: 0;
}
.in-text jb-richtext {
  --textarea-paragraph-margin-bottom: 0;
}`;

// src/components/jb-info-box/jb-info-box.ts
var JbInfoBox = class extends Initiable(Spaceable(LitElement)) {
  firstUpdated() {
    if (this.countryCondition && this.countryCondition.toLowerCase() !== JBCookie.getI18nCookieData().country.toLowerCase()) {
      this.style.setProperty("display", "none");
    }
  }
  _getContentSpacingProperties() {
    return { type: "text" };
  }
  _tracking(e) {
    const jbTrackingEvent = jbLinkClickedEventToJbTrackingEvent(e, {
      component_name: "info-box"
    });
    if (jbTrackingEvent) {
      this.dispatchEvent(jbTrackingEvent);
    }
  }
  render() {
    return html`
      <div
        class="${classMap({
      "in-text": !!this.isInText
    })}"
      >
        ${this.headline ? html`<h2>${this.headline}</h2>` : nothing}
        <jb-richtext @jb-link:clicked=${this._tracking}>${unsafeHTML(this.content)}</jb-richtext>
      </div>
    `;
  }
};
JbInfoBox.styles = unsafeCSS(jb_info_box_default);
__decorateClass([
  property()
], JbInfoBox.prototype, "content", 2);
__decorateClass([
  property()
], JbInfoBox.prototype, "countryCondition", 2);
__decorateClass([
  property()
], JbInfoBox.prototype, "headline", 2);
__decorateClass([
  property({ type: Boolean })
], JbInfoBox.prototype, "isInText", 2);
JbInfoBox = __decorateClass([
  customElement("jb-info-box")
], JbInfoBox);

export {
  JbInfoBox
};
