import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/jb-region-indicator-item/jb-region-indicator-item.ts
import { html, LitElement, nothing, unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";

// src/components/jb-region-indicator-item/jb-region-indicator-item.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_region_indicator_item_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
span {
  font-family: VerlagSSm, Tahoma, sans-serif;
  font-weight: 700;
}

span {
  color: rgb(var(--rgb-primary, 20, 30, 85));
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 16px;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

li {
  align-items: flex-end;
  display: inline-flex;
  list-style: none;
  padding-right: var(--padding-right, 0);
  position: relative;
}
li::after {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  content: ".";
  display: var(--dot-display, none);
  font-family: "Lucida Grande", sans-serif;
  font-weight: 700;
  position: absolute;
  right: 7px;
  top: 43%;
  transform: translateY(-50%);
  font-size: 11px;
  font-size: 0.6875rem;
  letter-spacing: 1px;
  letter-spacing: 0.0625rem;
}

span {
  color: rgb(var(--rgb-primary, 255, 255, 255));
  display: inline-block;
  line-height: 36px;
  text-decoration: none;
  text-transform: uppercase;
}
span:not(.short) {
  display: none;
}
@media (min-width: 960px) {
  span:not(.short) {
    display: inline;
  }
  span.short {
    display: none;
  }
}

jb-globe-icon {
  align-self: center;
  display: inline-block;
  height: 20px;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 1px;
  width: 20px;
}
@media (max-width: 599px) {
  jb-globe-icon {
    left: 0;
    margin-left: 0;
    top: 50%;
  }
}`;

// src/components/jb-region-indicator-item/jb-region-indicator-item.ts
var JbRegionIndicatorItem = class extends LitElement {
  constructor() {
    super(...arguments);
    this.hasGlobe = false;
  }
  render() {
    return html`
      <li>
        ${this.hasGlobe ? html`<jb-globe-icon></jb-globe-icon>` : nothing}
        <span class="short">${this.text}</span>
        <span>${this.textFull}</span>
      </li>
    `;
  }
};
JbRegionIndicatorItem.styles = unsafeCSS(jb_region_indicator_item_default);
__decorateClass([
  property({ type: Boolean })
], JbRegionIndicatorItem.prototype, "hasGlobe", 2);
__decorateClass([
  property()
], JbRegionIndicatorItem.prototype, "text", 2);
__decorateClass([
  property()
], JbRegionIndicatorItem.prototype, "textFull", 2);
JbRegionIndicatorItem = __decorateClass([
  customElement("jb-region-indicator-item")
], JbRegionIndicatorItem);

export {
  JbRegionIndicatorItem
};
