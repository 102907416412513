import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/mixins/Animatable.ts
import { property } from "lit/decorators.js";
function Animatable(baseClass) {
  class AnimatableMixin extends baseClass {
    constructor() {
      super(...arguments);
      this.contentDuration = 300;
      this.contentKeyframes = [{ opacity: 0 }, { opacity: 1 }];
      this.contentStagger = 150;
      this.wrapperDuration = 350;
      this.wrapperKeyframes = [
        { transform: "translateY(-100%)" },
        { transform: "translateY(0%)" }
      ];
    }
    connectedCallback() {
      super.connectedCallback();
    }
    async animateContent(hide = false) {
      if (!this._animateChildren)
        return false;
      return new Promise((resolve) => {
        for (let index = 0; index < this._animateChildren.length; index += 1) {
          const contentIndex = hide ? this._animateChildren.length - index - 1 : index;
          if (typeof this._animateChildren[contentIndex] !== "undefined") {
            const anim = this._animateChildren[contentIndex].animate(this.contentKeyframes, {
              duration: this.contentDuration,
              iterations: 1,
              direction: hide ? "reverse" : "normal",
              delay: this.contentStagger * index,
              fill: "both"
            });
            this._animateChildren[contentIndex].style.zIndex = (this._animateChildren.length - index - 1).toString();
            if (index === this._animateChildren.length - 1) {
              anim.onfinish = () => {
                this.dispatchEvent(new Event(hide ? "jb-reveal:contentHidden" : "jb-reveal:contentRevealed"));
                resolve(true);
              };
            }
          }
        }
      });
    }
    async animateWrapper(hide = false) {
      await customElements.whenDefined("jb-overlay");
      const animatedElement = this._parentOverlay ? this._parentOverlay.shadowRoot.querySelector("section") : this;
      const anim = animatedElement?.animate(this.wrapperKeyframes, {
        duration: this.wrapperDuration,
        iterations: 1,
        direction: hide ? "reverse" : "normal",
        fill: "both"
      });
      return new Promise((resolve) => {
        if (anim) {
          anim.onfinish = () => {
            this.dispatchEvent(new Event(hide ? "jb-reveal:wrapperHidden" : "jb-reveal:wrapperRevealed"));
            resolve(true);
          };
        } else {
          resolve(false);
        }
      });
    }
    async animateWrapperControls(hide = false) {
      const wrapperControls = [];
      if (this._parentOverlay && (this._parentOverlay.hasLogo || this._parentOverlay.hasCloseIcon)) {
        if (this._parentOverlay.hasLogo) {
          wrapperControls.push(this._parentOverlay.shadowRoot.querySelector(".overlay__logo-wrap"));
        }
        if (this._parentOverlay.hasCloseIcon) {
          wrapperControls.push(this._parentOverlay.shadowRoot.querySelector(".overlay__close-btn"));
        }
      }
      return new Promise((resolve) => {
        for (let index = 0; index < wrapperControls.length; index += 1) {
          const contentIndex = hide ? wrapperControls.length - index - 1 : index;
          if (typeof wrapperControls[contentIndex] !== "undefined") {
            const anim = wrapperControls[contentIndex].animate(this.contentKeyframes, {
              duration: this.contentDuration,
              iterations: 1,
              direction: hide ? "reverse" : "normal",
              fill: "both"
            });
            if (index === wrapperControls.length - 1) {
              anim.onfinish = () => {
                this.dispatchEvent(
                  new Event(hide ? "jb-reveal:wrapperControlsHidden" : "jb-reveal:wrapperControlsRevealed")
                );
                resolve(true);
              };
            }
          }
        }
      });
    }
    async hide() {
      await this.animateContent(true);
      if (this._parentOverlay && (this._parentOverlay.hasLogo || this._parentOverlay.hasCloseIcon)) {
        await this.animateWrapperControls(true);
      }
      await this.animateWrapper(true);
    }
    async reveal() {
      await this.animateWrapper();
      if (this._parentOverlay && (this._parentOverlay.hasLogo || this._parentOverlay.hasCloseIcon)) {
        await this.animateWrapperControls();
      }
      await this.animateContent();
    }
    get _animateChildren() {
      return this.contentChildren ?? this.shadowRoot?.querySelector("slot")?.assignedElements({ flatten: true });
    }
    get _parentOverlay() {
      if (this.parentElement && this.parentElement.tagName === "JB-OVERLAY")
        return this.parentElement;
      if (this.parentElement.parentElement && this.parentElement.parentElement.tagName === "JB-OVERLAY")
        return this.parentElement.parentElement;
      return false;
    }
  }
  __decorateClass([
    property({ type: Array })
  ], AnimatableMixin.prototype, "contentChildren", 2);
  __decorateClass([
    property({ type: Number })
  ], AnimatableMixin.prototype, "contentDuration", 2);
  __decorateClass([
    property({ type: Object })
  ], AnimatableMixin.prototype, "contentKeyframes", 2);
  __decorateClass([
    property({ type: Number })
  ], AnimatableMixin.prototype, "contentStagger", 2);
  __decorateClass([
    property({ type: Number })
  ], AnimatableMixin.prototype, "wrapperDuration", 2);
  __decorateClass([
    property({ type: Object })
  ], AnimatableMixin.prototype, "wrapperKeyframes", 2);
  return AnimatableMixin;
}

export {
  Animatable
};
