import {
  __decorateClass
} from "./chunk.7VONTVYH.js";

// src/components/icons/jb-clock-icon/jb-clock-icon.ts
import { html, LitElement, unsafeCSS } from "lit";
import { customElement } from "lit/decorators.js";

// src/components/icons/jb-clock-icon/jb-clock-icon.scss?inline
import { css } from "lit-element/lit-element.js";
var jb_clock_icon_default = css`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
:host {
  color: var(--host-color, #141e55);
}

svg {
  opacity: var(--jb-clock-opacity, 0.7);
}`;

// src/components/icons/jb-clock-icon/jb-clock-icon.ts
var JbClockIcon = class extends LitElement {
  render() {
    return html`
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.742 6.302C17.738 3.96 16.04 2.265 13.68 1.26A9.325 9.325 0 0 0 9.984.5a9.223 9.223 0 0 0-3.685.758c-2.347 1.007-4.05 2.705-5.052 5.044A9.241 9.241 0 0 0 .5 9.99c0 1.3.248 2.536.747 3.698 1.005 2.344 2.71 4.05 5.052 5.055 1.18.506 2.4.758 3.685.758a9.328 9.328 0 0 0 3.696-.758c2.353-1.005 4.055-2.706 5.062-5.055A9.292 9.292 0 0 0 19.5 9.99a9.234 9.234 0 0 0-.758-3.687zm-.705 8.36a9.276 9.276 0 0 1-3.395 3.385 9.11 9.11 0 0 1-4.658 1.25 8.963 8.963 0 0 1-3.611-.742c-1.15-.5-2.134-1.155-2.954-1.976-.822-.822-1.482-1.817-1.976-2.968a9.117 9.117 0 0 1-.729-3.622c0-1.676.413-3.23 1.238-4.65a9.28 9.28 0 0 1 3.383-3.385A9.042 9.042 0 0 1 9.984.704c1.672 0 3.234.419 4.658 1.25a9.302 9.302 0 0 1 3.395 3.384 9.055 9.055 0 0 1 1.249 4.651c0 1.68-.417 3.244-1.249 4.672zM9.882 9.915l.005.02c.012.055.012.055.014.196-.005.049-.001.062.02.084l3.27 3.27c.024.024.112.024.135 0 .049-.048.048-.097-.001-.147l-3.207-3.232V4.234c0-.053-.062-.113-.134-.113-.047 0-.102.055-.102.113v5.682z"
          stroke="#141E55"
          fill="none"
          fill-rule="evenodd"
        ></path>
      </svg>
    `;
  }
};
JbClockIcon.styles = unsafeCSS(jb_clock_icon_default);
JbClockIcon = __decorateClass([
  customElement("jb-clock-icon")
], JbClockIcon);

export {
  JbClockIcon
};
